<template>
  <div class="row">
    <div class="col-md-12">
      <card>
        <div>
          <h3>{{details.time}}</h3>
          <h3>{{details.user}}</h3>
          <h3>{{details.product}}</h3>
          <form @submit.prevent="submit">
            <div class="row" v-for="(field, index) in details.fields" :key="index">
              <label class="col-md-3 col-form-label">
                <multiLines v-html="n2br(field.name) || 'N/A'"></multiLines>
                <a v-if="field.help" :href="field.help" target="_blank">
                  <i class="tim-icons icon-support-17"></i>
                </a>
              </label>
              <div class="col-md-9">
                <base-input v-model="field.input"> </base-input>
              </div>
            </div>

            <base-button class="mt-3" native-type="submit" type="primary">
              {{ $t('Submit') }}
            </base-button>
            <base-button class="mt-3" type="default" @click="getDetails">
              {{ $t('Reset') }}
            </base-button>
          </form>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import { mapMutations } from 'vuex'
import { mapActions } from 'vuex'
import { BaseCheckbox, BaseRadio } from 'src/components/index';
import { TimeSelect, DatePicker, Select, Option } from 'element-ui';
import swal from 'sweetalert2';

export default {
  components: {
    BaseCheckbox,
    BaseRadio,
    [Option.name]: Option,
    [Select.name]: Select,
  },
  data() {
    return {
      radios: {
        radio1: '1',
        radio2: '2',
        radio3: '2',
        radioOn: '2',
        radioOff: '2'
      },
      checkboxes: {
        first: false,
        second: false,
        a: false,
        b: false,
        c: false,
        unchecked: false,
        checked: true,
        disabledUnchecked: false,
        disabledChecked: true
      },
      details: {}
    };
  },
  computed:{
    ...mapState([
      'toEdit'
    ]),
    ...mapGetters([
    ]),
  },
  methods: {
    ...mapMutations([
    ]),
    ...mapActions([
    ]),
    getDetails(){
      this.$http.get('details/'+this.toEdit)
      .then(response => response.json())
      .then(response => {
        this.details = response;
      })
      .catch(response => {
        if (response.status == 403)  this.$router.push('/logout');
      })
    },
    async submit() {
      let isValidForm = await this.$validator.validateAll();
      if (isValidForm) {
        this.$http.post('details/'+this.details.id, this.details.fields)
        .then( response => response.json())
        .then( response => {
          swal({
            title: this.$t('Thank you'),
            text: this.$t('orderDetailsDone'),
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-success btn-fill',
            type: 'success'
          }).then(result => {
            this.$router.push('/my/orders');
            this.getDetails();
          });
        })
        .catch( response => {
          if (response.status == 403)  this.$router.push('/logout');
          let errorMsg = 'No Updates';
          if(Array.isArray(response.body.error)){
            errorMsg = '<b>Validation Errors</b>';
            response.body.error.forEach(element => {
              errorMsg = errorMsg + '<br/>- ' + element;
            });
          }
          this.notifyVue(errorMsg, 'danger');
        })
      }
    },
    notifyVue(message = null, type = 'info', icon = 'tim-icons icon-bell-55', verticalAlign = 'top', horizontalAlign = 'right') {
      this.$notify({
          message,
          timeout: 15000,
          icon,
          horizontalAlign,
          verticalAlign,
          type
      });
    },
    n2br(val = null){
      if(!val) return null;
      return val.replace(/\n/g, "<br />");
    }
  },
  mounted() {
    this.details = {};
    this.getDetails();
  }
};
</script>
<style>
  table{
    width: 90%;
  }
  
  th{
    width: 30%;
    color: white;
  }

  td.td2{
    padding: 5px;
    padding-top: 25px;
  }
</style>
