<template>
  <div class="content">
    <h3>{{ $t('Orders') }}</h3>
    <div class="row mt-5">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <base-alert
            v-if="needDetails > 0"
            type="warning"
            dismissible icon="tim-icons icon-bell-55"
            >
            {{ $t('alertOrders') }}
          </base-alert>
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
             >
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="pagination.perPage"
                :placeholder="$t('Per page')"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <el-table :data="queriedData">
              <el-table-column :width="140" align="center" :label="$t('Image')">
                <div slot-scope="props">
                  <img v-if="props.row.image" :src="'products/'+props.row.image" :alt="$t('Product image')" />
                  <img v-else src="img/image_placeholder.jpg" :alt="$t('Product image')" />
                  <div style="margin-top: 5px">                    
                    <base-button
                      v-if="(props.row.status == 'Active' || props.row.status == 'To Stop') && props.row.details"
                      @click="handleDetails(props.$index, props.row)"
                      native-type="button"
                      type="info"
                      size="sm"
                      :title="$t('Edit Details')"
                      >
                      {{ $t('Details') }}
                    </base-button>
                    <base-button
                      v-if="props.row.status == 'Unpaid' || props.row.status == 'Details'"
                      @click="handleEditDetails(props.$index, props.row)"
                      native-type="button"
                      type="primary"
                      size="sm"
                      :title="$t('DetailsActivation')"
                      >
                      {{ $t('DetailsActivation') }}
                    </base-button>
                  </div>
                </div>
              </el-table-column>
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="$t(column.label)"
              >
              </el-table-column>
              <el-table-column align="right" :label="$t('Actions')">
                <div slot-scope="props">
                  <base-button
                    @click.native="reorder(props.$index, props.row)" :title="$t('Reorder')"
                    class="remove btn-link"
                    type="success"
                    size="sm"
                    icon
                    >
                    <i class="tim-icons icon-refresh-01"></i>
                  </base-button>
                  <base-button
                    @click.native="requestAdd(props.$index, props.row)" :title="$t('Request')"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-send"></i>
                  </base-button>
                  <base-button
                    v-if="props.row.review == 'empty'"
                    @click.native="reviewAdd(props.$index, props.row)" :title="$t('Review')"
                    class="edit btn-link"
                    type="success"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-heart-2"></i>
                  </base-button>
                  <base-button
                    @click.native="handleDetails(props.$index, props.row)" :title="$t('Details')"
                    class="remove btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-paper"></i>
                  </base-button>
                  <base-button
                    @click.native="noteEdit(props.$index, props.row)" :title="$t('Note')"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-pencil"></i>
                  </base-button>
                  <base-button
                    @click.native="handleDelete(props.$index, props.row)" :title="$t('Delete')"
                    class="remove btn-link"
                    type="danger"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-simple-remove"></i>
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t('Showing') }} {{ from + 1 }} {{ $t('to') }} {{ to }} {{ $t('of') }} {{ total }} {{ $t('entries') }}
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import { mapMutations } from 'vuex'
import { mapActions } from 'vuex'
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination } from 'src/components';
import swal from 'sweetalert2';
import { Modal, BaseAlert } from 'src/components';

export default {
  name: 'orders-dashboard',
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Modal,
    BaseAlert
  },
  data() {
    return {
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100 ,200],
        total: 0,
      },
      tableColumns: [
        {
          prop: 'product',
          label: 'Product',
          minWidth: 100
        },
        {
          prop: 'status',
          label: 'Status',
          minWidth: 75
        },
        {
          prop: 'start',
          label: 'Start',
          minWidth: 60
        },
        {
          prop: 'end',
          label: 'End',
          minWidth: 60
        },
        {
          prop: 'cnote',
          label: 'Note',
          minWidth: 100
        }
      ],
      tableData: [],
      searchedData: [],
      request: null
    };
  },
  computed:{
    ...mapState([
    ]),
    ...mapGetters([
    ]),
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
    needDetails() {
      let n = 0;
      this.tableData.forEach((el) => {
        if (el.status == 'Details') n += 1;
      });
      return n;
    }
  },
  methods: {
    ...mapMutations([
      'setToEdit',
      'setToDetails',
      'setToReorder'
    ]),
    ...mapActions([
    ]),
    reorder(index, row){
      this.setToReorder(row.id);
      this.$router.push('/my/reorder')
    },
    getOrders(){
      this.$http.get('orders')
        .then(response => response.json())
        .then(response => {
          this.tableData = response;
        })
        .catch(response => {
          if (response.status == 403)  this.$router.push('/logout');
      })
    },
    handleDetails(index, row) {
      index += (this.pagination.perPage * (this.pagination.currentPage-1));
      this.setToDetails(this.tableData[index].id);
      this.$router.push('/orders/details')
    },
    handleEditDetails(index, row) {
      index += (this.pagination.perPage * (this.pagination.currentPage-1));
      this.setToEdit(this.tableData[index].id);
      this.$router.push('/my/details')
    },
    reviewAdd(index, row) {
      index += (this.pagination.perPage * (this.pagination.currentPage-1));
      swal({
        title: this.$t('Input a review'),
        html: `<div class="form-group">
          <input id="orderReview" type="text" class="form-control" />
          </div>`,
        showCancelButton: false,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        buttonsStyling: false
      })
      .then(() => {
        if(document.getElementById('orderReview').value){
          this.$http.patch('orders/'+this.tableData[index].id,{
            review: document.getElementById('orderReview').value
          })
          .then(response => response.json())
          .then(response => {
            swal({
              type: 'success',
              html: this.$t('added'),
              confirmButtonClass: 'btn btn-success btn-fill',
              buttonsStyling: false
            });
            this.getOrders();
          })
          .catch(response => {
            if (response.status == 403){
              this.$router.push('/logout');
            }else{
              swal({
                type: 'error',
                html: this.$t("catNotAdd"),
                confirmButtonClass: 'btn btn-success btn-fill',
                buttonsStyling: false
              });
            }
          })
        }
      })
      .catch(swal.noop);
    },
    requestAdd(index, row) {
      index += (this.pagination.perPage * (this.pagination.currentPage-1));
      swal({
        title: this.$t('Input a request'),
        html: `<div class="form-group">
          <input id="orderRequest" type="text" class="form-control" value="" />
          </div>`,
        showCancelButton: false,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        buttonsStyling: false
      })
      .then(() => {
        this.$http.post('requests',{
          order: this.tableData[index].id,
          description: document.getElementById('orderRequest').value
        })
        .then(response => response.json())
        .then(response => {
          swal({
            type: 'success',
            html: this.$t('Sent'),
            confirmButtonClass: 'btn btn-success btn-fill',
            buttonsStyling: false
          });
          this.getOrders();
        })
        .catch(response => {
          if (response.status == 403){
            this.$router.push('/logout');
          }else{
            swal({
              type: 'error',
              html: this.$t("canNotSend"),
              confirmButtonClass: 'btn btn-success btn-fill',
              buttonsStyling: false
            });
          }
        })
      })
      .catch(swal.noop);
    },
    noteEdit(index, row) {
      index += (this.pagination.perPage * (this.pagination.currentPage-1));
      swal({
        title: this.$t('Input a note'),
        html: `<div class="form-group">
          <input id="orderNote" type="text" class="form-control" value="`+(this.tableData[index].cnote || '')+`" />
          </div>`,
        showCancelButton: false,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        buttonsStyling: false
      })
      .then(() => {
        this.$http.patch('orders/'+this.tableData[index].id,{
          cnote: document.getElementById('orderNote').value
        })
        .then(response => response.json())
        .then(response => {
          // swal({
          //   type: 'success',
          //   html: this.$t('Updated'),
          //   confirmButtonClass: 'btn btn-success btn-fill',
          //   buttonsStyling: false
          // });
          this.getOrders();
        })
        .catch(response => {
          if (response.status == 403){
            this.$router.push('/logout');
          }else{
            swal({
              type: 'error',
              html: this.$t("canNotUpdate"),
              confirmButtonClass: 'btn btn-success btn-fill',
              buttonsStyling: false
            });
          }
        })
      })
      .catch(swal.noop);
    },
    handleDelete(index, row) {
      index += (this.pagination.perPage * (this.pagination.currentPage-1));
      swal({
        title: this.$t('areYouSure'),
        text: this.$t('deleteConfirmation'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-danger btn-fill',
        cancelButtonClass: 'btn btn-default btn-fill',
        confirmButtonText: this.$t('yesDel'),
        cancelButtonText: this.$t('Cancel'),
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.$http.delete('orders/'+this.tableData[index].id)
            .then(response => response.json())
            .then(response => {
              let indexToDelete = this.tableData.findIndex(
                tableRow => tableRow.id === row.id
              );
              if (indexToDelete >= 0) {
                this.tableData.splice(indexToDelete, 1);
              }
              swal({
                title: this.$t('Deleted'),
                text: this.$t('Your request is sent'),
                type: 'success',
                confirmButtonClass: 'btn btn-success btn-fill',
                buttonsStyling: false
              });
            })
            .catch(response => {
              if (response.status == 403)  this.$router.push('/logout');
          })
        }
      });
    },
    notifyVue(message = null, type = 'info', icon = 'tim-icons icon-bell-55', verticalAlign = 'top', horizontalAlign = 'right') {
      this.$notify({
          message,
          timeout: 15000,
          icon,
          horizontalAlign,
          verticalAlign,
          type
      });
    }
  },
  mounted() {
    this.getOrders();
  }
};
</script>
<style>
.pagination-select {
  width: 200px;
}
</style>
