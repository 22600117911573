<template>
  <div class="content">
    <h3>Payment Confirmation</h3>
    <div class="row mt-5">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h5 slot="header" class="title">{{ $t('Confirmation Data') }}</h5>
          <p> - {{ $t('ifBankPayment') }}</p>
          <p> - {{ $t('ifCryptoPayment') }}</p>
          <p> - {{ $t('ifTransferPayment') }}</p>
          <form @submit.prevent="submit">
            <div class="row">
              <div class="col-md-12">
                <base-input label="Confirmation Note">
                  <textarea
                    class="form-control"
                    :placeholder="$t('Payment Confirmation Data')"
                    v-model="confirmation.note"
                  >
                  </textarea>
                </base-input>
              </div>
            </div>

            <!-- <div class="row" id="confirmationImage">
              <label class="col-md-3 col-form-label">Confirmation Image</label>
              <div class="col-md-9">
                <image-upload type="avatar" @change="newImage" select-text="Select Image" />
              </div>
            </div> -->
            
            <base-button native-type="submit" type="primary" class="btn-fill">
              Save
            </base-button>
            <base-button native-type="button" type="default" class="btn-fill" @click="confirmation = {}">
              {{ $t('Reset') }}
            </base-button>
          </form>
        </card>
      </div>
      <div class="col-12">
        <card>
          <div class="card-body">
            <div class="typography-line"><h4><span>{{ $t('Payment Method') }}</span>{{payment.method || 'N/A'}}</h4></div>
            <div class="typography-line"><h4><span>{{ $t('Status') }}</span>{{payment.status || 'N/A'}}</h4></div>
            <div class="typography-line"><h4><span>{{ $t('Amount') }}</span>{{payment.amount || 'N/A'}}</h4></div>
            <div class="typography-line"><h4><span>{{ $t('Added') }}</span>{{payment.added || 'N/A'}}</h4></div>
            <div class="typography-line"><h4><span>{{ $t('Paid') }}</span>{{payment.paidLocal || 'N/A'}}</h4></div>
            <div v-if="payment.confirmationNote" class="typography-line">
              <h4>
                <span>Confirmation Note</span>
                <multiLines v-html="n2br(payment.confirmationNote) || 'N/A'"></multiLines>
              </h4>
            </div>
            <!-- <div class="typography-line"><h4><span>Confirmation Image</span>{{payment.confirmationImage || 'N/A'}}</h4></div> -->
          </div>
        </card>
      </div>
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4>{{ $t('Orders') }}</h4>
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="pagination.perPage"
                :placeholder="$t('Per page')"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <el-table :data="queriedData">
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="$t(column.label)"
              >
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t('Showing') }} {{ from + 1 }} {{ $t('to') }} {{ to }} {{ $t('of') }} {{ total }} {{ $t('entries') }}
              </p>
            </div>
            <base-pagination
              class="pagination-no-bpayment"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import { mapMutations } from 'vuex'
import { mapActions } from 'vuex'
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination } from 'src/components';
import swal from 'sweetalert2';
import { Modal, BaseAlert } from 'src/components';

export default {
  name: 'payments-details',
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Modal,
    BaseAlert
  },
  data() {
    return {
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100 ,200],
        total: 0
      },
      tableColumns: [
        {
          prop: 'product',
          label: 'Product',
          minWidth: 150
        },
        {
          prop: 'status',
          label: 'Status',
          minWidth: 75
        },
        {
          prop: 'sell',
          label: 'Price',
          minWidth: 35
        },
        {
          prop: 'start',
          label: 'Start',
          minWidth: 75
        },
        {
          prop: 'end',
          label: 'End',
          minWidth: 75
        },
        {
          prop: 'cnote',
          label: 'Note',
          minWidth: 75
        }
      ],
      payment: {},
      // payments: false,
      orders: false,
      confirmation: {},
      tableData: [],
      searchedData: [],
    };
  },
  computed:{
    ...mapState([
      'toDetails'
    ]),
    ...mapGetters([
    ]),
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  methods: {
    ...mapMutations([
    ]),
    ...mapActions([
    ]),
    async submit() {
      let isValidForm = await this.$validator.validateAll();
      if (isValidForm) {
        this.$http.put('confirm/'+this.toDetails, this.confirmation)
        .then( response => response.json())
        .then( response => {

          swal({
            title: this.$t('Thank you'),
            text: this.$t('paymentConfirmationDone'),
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-success btn-fill',
            type: 'success'
          }).then(result => {
            if (this.orders) {
              swal({
                title: this.$t('New Orders Message'),
                text: this.$t('alertOrders'),
                type: 'success',
                showCancelButton: true,
                confirmButtonClass: 'btn btn-primary btn-fill',
                cancelButtonClass: 'btn btn-danger btn-fill',
                confirmButtonText: this.$t('goAddOrderDetails'),
                buttonsStyling: false
              }).then(result => {
                if (result.value) {
                  this.$router.push('/my/orders');
                }else{
                  this.$router.push('/my/payments');
                }
              });
            }else{
              this.$router.push('/my/payments');
            }
          });
        })
        .catch( response => {
          if (response.status == 403)  this.$router.push('/logout');
          let errorMsg = 'No Updates';
          if(Array.isArray(response.body.error)){
            errorMsg = '<b>Validation Errors</b>';
            response.body.error.forEach(element => {
              errorMsg = errorMsg + '<br/>- ' + element;
            });
          }
          this.notifyVue(errorMsg, 'danger');
        })
      }
    },
    // newImage(file){
    //   let reader = new FileReader();
    //   let vm = this;
    //   reader.onloadend = function (e) {
    //     var b64 = reader.result;
    //     // var b64 = reader.result.replace(/^data:.+;base64,/, '');
    //     vm.confirmation.image = b64;
    //   };

    //   reader.readAsDataURL(file);
    // },
    notifyVue(message = null, type = 'info', icon = 'tim-icons icon-bell-55', verticalAlign = 'top', horizontalAlign = 'right') {
      this.$notify({
          message,
          timeout: 15000,
          icon,
          horizontalAlign,
          verticalAlign,
          type
      });
    },
    getPayments(){
      this.$http.get('payments/'+this.toDetails)
        .then(response => response.json())
        .then(response => {
          this.payment = response;
          this.tableData = response.orders;
        })
        .catch(response => {
          if (response.status == 403)  this.$router.push('/logout');
      })
    },
    n2br(val = null){
      if(!val) return null;
      return val.replace(/\n/g, "<br />");
    },
    getAfterOrder(){
      this.$http.get('afterorder')
        .then(response => response.json())
        .then(response => {
          // this.payments = response.payments.length > 0;
          this.orders = response.orders.length > 0;
        })
        .catch(response => {
          if (response.status == 403)  this.$router.push('/logout');
      })
    }
  },
  mounted() {
    this.getPayments();
    this.getAfterOrder();
  }
};
</script>
<style>
.pagination-select {
  width: 200px;
}
</style>
