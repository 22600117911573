<template>
  <div class="content">
    <h3>{{ $t('Payments') }}</h3>
    <div class="row mt-5">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <base-alert
            v-if="needConfirm > 0"
            type="warning"
            dismissible icon="tim-icons icon-bell-55"
            >
            {{ $t('alertPayments') }}
          </base-alert>
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="pagination.perPage"
                :placeholder="$t('Per page')"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <el-table :data="queriedData">
              <el-table-column width="122" align="center" label="Date">
                <div slot-scope="props">
                  {{props.row.time}}
                  <div style="margin-top: 5px">                    
                    <base-button
                      v-if="props.row.confirm == 'Yes'"
                      @click="handleConfirm(props.$index, props.row)"
                      native-type="button"
                      type="primary"
                      size="sm"
                      :title="$t('Edit Confirmation')"
                      >
                      {{ $t('Conf.') }}
                    </base-button>
                  </div>
                </div>
              </el-table-column>
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="$t(column.label)"
              >
              </el-table-column>
              <el-table-column align="right" :label="$t('Actions')">
                <div slot-scope="props">
                  <base-button
                    @click.native="showDetails(props.$index, props.row)" :title="$t('Details')"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                    >
                    <i class="tim-icons icon-paper"></i>
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t('Showing') }} {{ from + 1 }} {{ $t('to') }} {{ to }} {{ $t('of') }} {{ total }} {{ $t('entries') }}
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import { mapMutations } from 'vuex'
import { mapActions } from 'vuex'
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination } from 'src/components';
import { Modal, BaseAlert } from 'src/components';

export default {
  name: 'payments-dashboard',
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Modal,
    BaseAlert
  },
  data() {
    return {
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100 ,200],
        total: 0
      },
      tableColumns: [
        {
          prop: 'method',
          label: 'Method',
          minWidth: 75
        },
        {
          prop: 'status',
          label: 'Status',
          minWidth: 75
        },
        {
          prop: 'amount',
          label: 'Amount',
          minWidth: 50
        }
      ],
      tableData: [],
      searchedData: [],
    };
  },
  computed:{
    ...mapState([
    ]),
    ...mapGetters([
    ]),
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
    needConfirm() {
      let n = 0;
      this.tableData.forEach((el) => {
        if (el.confirm == 'Yes') n += 1;
      });
      return n;
    }
  },
  methods: {
    ...mapMutations([
      'setToDetails'
    ]),
    ...mapActions([
    ]),
    getPayments(){
      this.$http.get('payments')
        .then(response => response.json())
        .then(response => {
          this.tableData = response;
        })
        .catch(response => {
          if (response.status == 403)  this.$router.push('/logout');
      })
    },
    notifyVue(message = null, type = 'info', icon = 'tim-icons icon-bell-55', verticalAlign = 'top', horizontalAlign = 'right') {
      this.$notify({
          message,
          timeout: 15000,
          icon,
          horizontalAlign,
          verticalAlign,
          type
      });
    },
    handleConfirm(index, row) {
      index += (this.pagination.perPage * (this.pagination.currentPage-1));
      this.setToDetails(this.tableData[index].code);
      this.$router.push('/my/confirmation')
    },
    showDetails(index, row) {
      index += (this.pagination.perPage * (this.pagination.currentPage-1));
      this.setToDetails(this.tableData[index].code);
      this.$router.push('/my/paymentDetails')
    }
  },
  mounted() {
    this.getPayments();
  }
};
</script>
<style>
.pagination-select {
  width: 200px;
}
</style>
