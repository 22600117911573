<template>
  <div class="content">
    <base-alert
      v-if="payments"
      type="warning"
      dismissible icon="tim-icons icon-bell-55"
      >
      You have some payments needs confirmation, you must enter this data in payments page
    </base-alert>
    <base-alert
      v-if="orders"
      type="warning"
      dismissible icon="tim-icons icon-bell-55"
      >
      You have some orders in status 'Details', you must enter this products details by click on confirmation button so we can activate it
    </base-alert>
    <h3 v-if="payments">Payments</h3>
    <div class="row mt-5" v-if="payments">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
             >
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="paymentsPagination.perPage"
                :placeholder="$t('Per page')"
              >
                <el-option
                  class="select-primary"
                  v-for="item in paymentsPagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <el-table :data="paymentsQueriedData">
              <el-table-column width="122" align="center" label="Date">
                <div slot-scope="props">
                  {{ props.row.time }}
                  <div style="margin-top: 5px">
                    <base-button
                      v-if="props.row.confirm == 'Yes'"
                      @click="handlePaymentConfirm(props.$index, props.row)"
                      native-type="button"
                      type="primary"
                      size="sm"
                      :title="$t('Edit Confirmation')"
                      >
                      {{ $t('Conf.') }}
                    </base-button>
                  </div>
                </div>
              </el-table-column>
              <el-table-column
                v-for="column in paymentsTableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="$t(column.label)"
              >
              </el-table-column>
              <el-table-column align="right" :label="$t('Actions')">
                <div slot-scope="props">
                  <base-button
                    @click.native="showPaymentDetails(props.$index, props.row)" :title="$t('Details')"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                    >
                    <i class="tim-icons icon-paper"></i>
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t('Showing') }} {{ paymentsFrom + 1 }} {{ $t('to') }} {{ paymentsTo }} {{ $t('of') }} {{ paymentsTotal }} {{ $t('entries') }}
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="paymentsPagination.currentPage"
              :per-page="paymentsPagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
    <h3 v-if="orders">{{ $t('Orders') }}</h3>
    <div class="row mt-5" v-if="orders">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
             >
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="ordersPagination.perPage"
                :placeholder="$t('Per page')"
              >
                <el-option
                  class="select-primary"
                  v-for="item in ordersPagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <el-table :data="ordersQueriedData">
              <el-table-column :max-width="100" align="left" :label="$t('Image')">
                <div slot-scope="props">
                  <img v-if="props.row.image" :src="'products/'+props.row.image" :alt="$t('Product image')" />
                  <img v-else src="img/image_placeholder.jpg" :alt="$t('Product image')" />
                  <div style="margin-top: 5px">                    
                    <base-button
                      v-if="props.row.status == 'Unpaid' || props.row.status == 'Details'"
                      @click="handleOrderEditDetails(props.$index, props.row)"
                      native-type="button"
                      type="primary"
                      size="sm"
                      :title="$t('Edit Details')"
                      >
                      Details
                    </base-button>
                  </div>
                </div>
              </el-table-column>
              <el-table-column
                v-for="column in ordersTableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="$t(column.label)"
              >
              </el-table-column>
              <el-table-column align="right" :label="$t('Actions')">
                <div slot-scope="props">
                  <base-button
                    @click.native="reorder(props.$index, props.row)" :title="$t('Reorder')"
                    class="remove btn-link"
                    type="success"
                    size="sm"
                    icon
                    >
                    <i class="tim-icons icon-refresh-01"></i>
                  </base-button>
                  <base-button
                    @click.native="requestAdd(props.$index, props.row)" :title="$t('Request')"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-send"></i>
                  </base-button>
                  <base-button
                    @click.native="handleOrderDetails(props.$index, props.row)" :title="$t('Details')"
                    class="remove btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-paper"></i>
                  </base-button>
                  <base-button
                    @click.native="noteEdit(props.$index, props.row)" :title="$t('Note')"
                    class="edit btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-pencil"></i>
                  </base-button>
                  <base-button
                    @click.native="handleOrderDelete(props.$index, props.row)" :title="$t('Delete')"
                    class="remove btn-link"
                    type="danger"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-simple-remove"></i>
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t('Showing') }} {{ ordersFrom + 1 }} {{ $t('to') }} {{ ordersTo }} {{ $t('of') }} {{ ordersTotal }} {{ $t('entries') }}
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="ordersPagination.currentPage"
              :per-page="ordersPagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import { mapMutations } from 'vuex'
import { mapActions } from 'vuex'
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination } from 'src/components';
import swal from 'sweetalert2';
import { Modal, BaseAlert } from 'src/components';

export default {
  name: 'orders-dashboard',
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Modal,
    BaseAlert
  },
  data() {
    return {
      paymentsPagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100 ,200],
        total: 0,
      },
      ordersPagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100 ,200],
        total: 0,
      },
      paymentsTableColumns: [
        {
          prop: 'method',
          label: 'Method',
          minWidth: 75
        },
        {
          prop: 'status',
          label: 'Status',
          minWidth: 75
        },
        {
          prop: 'amount',
          label: 'Amount',
          minWidth: 50
        }
      ],
      ordersTableColumns: [
        {
          prop: 'product',
          label: 'Product',
          minWidth: 100
        },
        {
          prop: 'status',
          label: 'Status',
          minWidth: 75
        },
        {
          prop: 'start',
          label: 'Start',
          minWidth: 60
        },
        {
          prop: 'end',
          label: 'End',
          minWidth: 60
        },
        {
          prop: 'cnote',
          label: 'Note',
          minWidth: 100
        }
      ],
      paymentsData: [],
      ordersData: [],
      searchedData: [],
      payments: false,
      orders: false,
      fuseSearch: null,
      request: null
    };
  },
  // watch:{
  //   payments:(val) => {
  //     this.paymentsAlert();
  //   },
  //   orders:(val) => {
  //     this.ordersAlert();
  //   }
  // },
  computed:{
    ...mapState([
    ]),
    ...mapGetters([
    ]),
    paymentsQueriedData() {
      let result = this.paymentsData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    paymentsTo() {
      let highBound = this.from + this.paymentsPagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    paymentsFrom() {
      return this.paymentsPagination.perPage * (this.paymentsPagination.currentPage - 1);
    },
    paymentsTotal() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.paymentsData.length;
    },
    ordersQueriedData() {
      let result = this.ordersData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    ordersTo() {
      let highBound = this.from + this.ordersPagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    ordersFrom() {
      return this.ordersPagination.perPage * (this.ordersPagination.currentPage - 1);
    },
    ordersTotal() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.ordersData.length;
    }
  },
  methods: {
    ...mapMutations([
      'setToEdit',
      'setToDetails',
      'setToReorder'
    ]),
    ...mapActions([
    ]),
    reorder(index, row){
      this.setToReorder(row.id);
      this.$router.push('/my/reorder')
    },
    getAfterOrder(){
      this.$http.get('afterorder')
        .then(response => response.json())
        .then(response => {
          this.payments = response.payments.length > 0;
          this.orders = response.orders.length > 0;
          if(this.payments || this.orders){
            if(this.payments) this.paymentsData = response.payments;
            if(this.orders) this.ordersData = response.orders;
            if(this.payments) this.paymentsAlert();
            else if(this.orders) this.ordersAlert();
          }else{
            this.$router.push('/');
          }
        })
        .catch(response => {
          if (response.status == 403)  this.$router.push('/logout');
      })
    },
    handleOrderDetails(index, row) {
      index += (this.ordersPagination.perPage * (this.ordersPagination.currentPage-1));
      this.setToDetails(this.ordersData[index].id);
      this.$router.push('/orders/details')
    },
    handleOrderEditDetails(index, row) {
      index += (this.ordersPagination.perPage * (this.ordersPagination.currentPage-1));
      this.setToEdit(this.ordersData[index].id);
      this.$router.push('/my/details')
    },
    requestAdd(index, row) {
      index += (this.ordersPagination.perPage * (this.ordersPagination.currentPage-1));
      swal({
        title: this.$t('Input a request'),
        html: `<div class="form-group">
          <input id="orderRequest" type="text" class="form-control" value="" />
          </div>`,
        showCancelButton: false,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        buttonsStyling: false
      })
      .then(() => {
        this.$http.post('requests',{
          order: this.ordersData[index].id,
          description: document.getElementById('orderRequest').value
        })
        .then(response => response.json())
        .then(response => {
          swal({
            type: 'success',
            html: this.$t('Sent'),
            confirmButtonClass: 'btn btn-success btn-fill',
            buttonsStyling: false
          });
          this.getAfterOrder();
        })
        .catch(response => {
          if (response.status == 403){
            this.$router.push('/logout');
          }else{
            swal({
              type: 'error',
              html: this.$t("canNotSend"),
              confirmButtonClass: 'btn btn-success btn-fill',
              buttonsStyling: false
            });
          }
        })
      })
      .catch(swal.noop);
    },
    noteEdit(index, row) {
      index += (this.ordersPagination.perPage * (this.ordersPagination.currentPage-1));
      swal({
        title: this.$t('Input a note'),
        html: `<div class="form-group">
          <input id="orderNote" type="text" class="form-control" value="`+(this.ordersData[index].cnote || '')+`" />
          </div>`,
        showCancelButton: false,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        buttonsStyling: false
      })
      .then(() => {
        this.$http.patch('orders/'+this.ordersData[index].id,{
          cnote: document.getElementById('orderNote').value
        })
        .then(response => response.json())
        .then(response => {
          // swal({
          //   type: 'success',
          //   html: this.$t('Updated'),
          //   confirmButtonClass: 'btn btn-success btn-fill',
          //   buttonsStyling: false
          // });
          this.getAfterOrder();
        })
        .catch(response => {
          if (response.status == 403){
            this.$router.push('/logout');
          }else{
            swal({
              type: 'error',
              html: this.$t("canNotUpdate"),
              confirmButtonClass: 'btn btn-success btn-fill',
              buttonsStyling: false
            });
          }
        })
      })
      .catch(swal.noop);
    },
    handleOrderDelete(index, row) {
      index += (this.ordersPagination.perPage * (this.ordersPagination.currentPage-1));
      swal({
        title: this.$t('areYouSure'),
        text: this.$t('deleteConfirmation'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-danger btn-fill',
        cancelButtonClass: 'btn btn-default btn-fill',
        confirmButtonText: this.$t('yesDel'),
        cancelButtonText: this.$t('Cancel'),
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.$http.delete('orders/'+this.ordersData[index].id)
            .then(response => response.json())
            .then(response => {
              let indexToDelete = this.ordersData.findIndex(
                tableRow => tableRow.id === row.id
              );
              if (indexToDelete >= 0) {
                this.ordersData.splice(indexToDelete, 1);
              }
              swal({
                title: this.$t('Deleted'),
                text: this.$t('Your request is sent'),
                type: 'success',
                confirmButtonClass: 'btn btn-success btn-fill',
                buttonsStyling: false
              });
            })
            .catch(response => {
              if (response.status == 403)  this.$router.push('/logout');
          })
        }
      });
    },
    handlePaymentConfirm(index, row) {
      index += (this.paymentsPagination.perPage * (this.paymentsPagination.currentPage-1));
      this.setToDetails(this.paymentsData[index].code);
      this.$router.push('/my/confirmation')
    },
    showPaymentDetails(index, row) {
      index += (this.paymentsPagination.perPage * (this.paymentsPagination.currentPage-1));
      this.setToDetails(this.paymentsData[index].code);
      this.$router.push('/my/paymentDetails')
    },
    notifyVue(message = null, type = 'info', icon = 'tim-icons icon-bell-55', verticalAlign = 'top', horizontalAlign = 'right') {
      this.$notify({
          message,
          timeout: 15000,
          icon,
          horizontalAlign,
          verticalAlign,
          type
      });
    },
    paymentsAlert() {
      swal({
        title: this.$t('Payed'),
        text: this.$t('alertPayments'),
        type: 'success',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-primary btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: this.$t('goAddPaymentConfirmation'),
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.$router.push('/my/payments');
        }else{
          if(this.orders) this.ordersAlert();
        }
      });
    },
    ordersAlert() {
      swal({
        title: this.$t('New Orders'),
        text: this.$t('alertOrders'),
        type: 'success',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-primary btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: this.$t('goAddOrderDetails'),
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.$router.push('/my/orders');
        }
      });
    }
  },
  mounted() {
    this.getAfterOrder();
  }
};
</script>
<style>
  .pagination-select {
    width: 200px;
  }
</style>
