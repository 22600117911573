<template>
  <div class="content">
    <h3>Paymentwall {{ $t('Payment Processing') }}</h3>
    <div class="row mt-5">
      <div class="col-md-12">
        <card type="testimonial">
          <a slot="header" href="#">
            <img class="img img-raised" src="img/paymentwall.png" alt="Card image" >
          </a>

          <p class="card-description" v-show="!error">
            {{ $t('paymentSuccess') }}.
            <br>{{ $t('redirectMessage') }}.
          </p>
          <p v-show="error">
            {{ $t('paymentError') }}.
          </p>
          <div class="icon icon-primary"><i class="fa fa-quote-right"></i></div>

          <template slot="footer">
            <h4 class="card-title">{{ storeName }}</h4>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'paymentwall-processing',
  data() {
    return {
      error: false,
    };
  },
  computed:{
    ...mapGetters([
      'getResources'
    ]),
    storeName() {
      return this.getResources.store;
    }
  },
  methods: {
    postPayment(){
        this.error = false;
        this.$http.post('paymentwallcheck',{
            payment: this.$route.query.payment,
        })
        .then(response => response.json())
        .then(response => {
            this.notifyVue(response.message, 'success');
            this.$router.push('/my/afterorder');
        })
        .catch(response => {
            if (response.status == 403)  this.$router.push('/logout');
            this.error = true;
            this.notifyVue(response.body.error, 'danger');
        })
    },
    notifyVue(message = null, type = 'info', icon = 'tim-icons icon-bell-55', verticalAlign = 'top', horizontalAlign = 'right') {
      this.$notify({
          message,
          timeout: 15000,
          icon,
          horizontalAlign,
          verticalAlign,
          type
      });
    }
  },
  mounted() {
    this.postPayment();
  }
};
</script>
<style>
.pagination-select {
  width: 200px;
}
</style>
